import React, { useState, useEffect } from 'react';
import { Menu, X } from 'lucide-react';

const navItems = [
  { id: 'inicio', label: 'Inicio' },
  { id: 'como-funciona', label: 'Cómo Funciona' },
  { id: 'por-que-elegirnos', label: 'Por Qué Elegirnos' },
  { id: 'testimonios', label: 'Testimonios' },
  { id: 'preguntas-frecuentes', label: 'Preguntas Frecuentes' },
];

const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeSection, setActiveSection] = useState('inicio');

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 10);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const scrollTo = (id) => {
    const element = document.getElementById(id);
    element.scrollIntoView({ behavior: 'smooth' });
    setActiveSection(id);
    setIsMenuOpen(false);
  };

  return (
    <header className={`fixed w-full z-10 transition-all duration-300 ${isScrolled ? 'py-2' : 'py-4'} mt-4 px-2 md:px-0`}>
      <nav 
        className={`
          container mx-auto px-4 bg-white shadow-lg transition-all duration-300 
          ${isScrolled ? 'max-w-6xl' : 'max-w-7xl'}
          rounded-2xl overflow-hidden
        `}
      >
        <div className="flex justify-between items-center py-3">
          <button 
            onClick={() => scrollTo('inicio')}
            className="flex items-center focus:outline-none"
          >
            <img src="https://storage.tally.so/c6a2bb26-7e97-4430-9245-ba6de8adfb4f/favicon_catalizador.png" alt="Logo" className="h-10 w-10 mr-2 rounded-full" />
            <span className="font-bold text-blue-600 text-lg">VendeTuCatalizador.com.mx</span>
          </button>
          
          {/* Desktop Menu */}
          <ul className="hidden md:flex space-x-6">
            {navItems.map((item) => (
              <li key={item.id}>
                <button
                  onClick={() => scrollTo(item.id)}
                  className={`hover:text-blue-500 transition-colors ${
                    activeSection === item.id ? 'font-bold text-blue-600' : 'text-gray-600'
                  }`}
                >
                  {item.label}
                </button>
              </li>
            ))}
          </ul>
          
          {/* Mobile Menu Button */}
          <button 
            className="md:hidden text-blue-600"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
          </button>
        </div>
        
        {/* Mobile Menu */}
        <div 
          className={`
            md:hidden transition-all duration-300 ease-in-out
            ${isMenuOpen ? 'max-h-96 opacity-100' : 'max-h-0 opacity-0'}
          `}
        >
          <ul className="py-4">
            {navItems.map((item) => (
              <li key={item.id} className="mb-2">
                <button
                  onClick={() => scrollTo(item.id)}
                  className={`w-full text-left py-2 px-4 rounded hover:bg-blue-50 transition-colors ${
                    activeSection === item.id ? 'font-bold text-blue-600' : 'text-gray-600'
                  }`}
                >
                  {item.label}
                </button>
              </li>
            ))}
            {/* Static CTA Button */}
            <li className="mb-2 mt-4">
              <a
                href="https://cotizador.vendetucatalizador.com.mx"
                className="w-full flex justify-center bg-blue-600 text-white py-3 px-8 rounded-full text-lg font-semibold hover:bg-blue-700 duration-300 shadow-lg"
              >
                Obtén tu Cotización Ahora
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  );
};

export default Header;