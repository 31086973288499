import React, { useState } from 'react';
import { ChevronDown, DollarSign, Truck, CheckCircle, Star, MessageCircle } from 'lucide-react';
import Header from './components/Header';

const testimonials = [
  { id: 1, name: 'Juan Pérez', text: 'Excelente servicio, muy rápido y el mejor precio del mercado.' },
  { id: 2, name: 'María González', text: 'Proceso muy sencillo. Recomiendo ampliamente VendeTuCatalizador.' },
  { id: 3, name: 'Carlos Rodríguez', text: 'Increíble lo fácil que fue vender mi catalizador. Gracias por su profesionalismo.' },
];

const FAQ = [
  { 
    question: '¿Cuánto tiempo tarda el proceso?', 
    answer: 'El proceso generalmente toma entre 24 a 48 horas desde que recibes tu cotización hasta que recibes tu pago.' 
  },
  { 
    question: '¿Cómo se determina el precio de mi catalizador?', 
    answer: 'El precio se determina basándose en el modelo específico del catalizador que deseas vender. Requerimos conocer específicamente el catalizador que deseas vender ya que existen muchas opciones en el mercado.\nPor eso te pedimos que completes nuestro formulario de cotización para que podamos ofrecerte el mejor precio posible.' 
  },
  { 
    question: '¿Ofrecen servicio en toda la República Mexicana?', 
    answer: 'Por el momento solo contamos con servicio de recogida a domicilio en CDMX y algunos municipios del Estado de México. Sin embargo, podemos recibir tu catalizador por paquetería desde cualquier lugar de la República Mexicana. Tu pago está 100% garantizado.' 
  },
  { 
    question: '¿Es legal vender mi catalizador usado?', 
    answer: 'Sí, es completamente legal vender tu catalizador usado. Nosotros nos encargamos de su reciclaje responsable.' 
  },
  { 
    question: '¿Cómo se realiza el pago?', 
    answer: 'Ofrecemos varias opciones de pago, incluyendo transferencia bancaria y efectivo. Puedes elegir la que más te convenga.' 
  },
];

export default function VendeTuCatalizadorApp() {
  const [activeAccordion, setActiveAccordion] = useState(null);

  const toggleAccordion = (index) => {
    setActiveAccordion(activeAccordion === index ? null : index);
  };

  return (
    <div className="font-sans bg-gray-100 text-gray-800">
      <Header />

      <main>
      <section id="inicio" className="bg-blue-500 text-white min-h-screen flex flex-col justify-between pt-28 md:pt-32">
        <div className="flex-grow flex items-center">
          <div className="container mx-auto px-4">
            <div className="flex flex-col md:flex-row items-center justify-between">
              {/* Left side - Text content */}
              <div className="w-full md:w-1/2 text-center md:text-left mb-8 md:mb-0">
                <span className="inline-block bg-blue-600 text-white text-xs px-3 py-1 rounded-full uppercase tracking-wide mb-4">
                  VENDETUCATALIZADOR.COM.MX
                </span>
                <h1 className="text-3xl md:text-5xl font-bold mb-6">Rápidamente y al Mejor Precio</h1>
                <a
                  href="https://cotizador.vendetucatalizador.com.mx"
                  className="bg-white text-blue-600 py-3 px-8 rounded-full text-lg font-semibold hover:bg-blue-50 transition duration-300 shadow-lg"
                >
                  Obtén tu Cotización Ahora
                </a>
              </div>
              
              {/* Right side - Image */}
              <div className="w-full md:w-1/2 flex justify-center md:justify-end">
                <img 
                  src={process.env.PUBLIC_URL + '/images/PAGOSEGURO.png'} 
                  alt="Representación de servicio rápido y mejor precio" 
                  className="max-w-full h-auto"
                />
              </div>
            </div>
          </div>
        </div>

        {/* Scroll down indicator */}
        <div className="text-center pb-8 mt-8 md:mt-0">
          <p className="mb-2">Checa cómo lo hacemos</p>
          <ChevronDown className="mx-auto animate-bounce" size={24} />
        </div>
      </section>

        <section id="como-funciona" className="py-20 bg-white">
          <div className="container mx-auto px-4">
            <h2 className="text-4xl font-bold text-center mb-16 text-blue-600">¿Cómo Funciona?</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
              {[
                { icon: <ChevronDown size={40} />, title: "Identifica el Código", description: "Usa nuestra guía visual para encontrar el código de tu catalizador." },
                { icon: <MessageCircle size={40} />, title: "Completa el Formulario", description: "Ingresa detalles de tu catalizador y tu información de contacto." },
                { icon: <Truck size={40} />, title: "Elige Método de Entrega", description: "Opta por recogida a domicilio o entrega en sucursal." },
                { icon: <DollarSign size={40} />, title: "Tu Dinero en Mano", description: "Una vez verifiquemos tu catalizador, recibe tu dinero." },
              ].map((step, index) => (
                <div key={index} className="bg-blue-50 p-6 rounded-lg shadow-md text-center">
                  <div className="bg-blue-500 text-white rounded-full p-4 inline-block mb-4">
                    {step.icon}
                  </div>
                  <h3 className="font-semibold text-xl mb-2 text-blue-700">{step.title}</h3>
                  <p className="text-gray-600">{step.description}</p>
                </div>
              ))}
            </div>
          </div>
        </section>

        <section id="por-que-elegirnos" className="py-20 bg-gray-100">
          <div className="container mx-auto px-4">
            <h2 className="text-4xl font-bold text-center mb-16 text-blue-600">¿Por Qué Elegirnos?</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              {[
                { icon: <DollarSign size={30} />, title: "Cotizaciones Justas y Competitivas", description: "Ofrecemos los mejores precios del mercado por tu catalizador usado." },
                { icon: <CheckCircle size={30} />, title: "Proceso Rápido y Sencillo", description: "Nuestro sistema está diseñado para ahorrarte tiempo y esfuerzo." },
                { icon: <Star size={30} />, title: "Pago Seguro y Confiable", description: "Garantizamos transacciones seguras y transparentes." },
                { icon: <Truck size={30} />, title: "Opciones Flexibles de Entrega", description: "Adaptamos nuestro servicio a tu conveniencia." },
              ].map((reason, index) => (
                <div key={index} className="bg-white p-6 rounded-lg shadow-lg flex items-start">
                  <div className="bg-blue-500 text-white rounded-full p-2 mr-4">
                    {reason.icon}
                  </div>
                  <div>
                    <h3 className="font-semibold text-xl mb-2 text-blue-700">{reason.title}</h3>
                    <p className="text-gray-600">{reason.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>

        <section id="testimonios" className="py-20 bg-blue-50">
          <div className="container mx-auto px-4">
            <h2 className="text-4xl font-bold text-center mb-16 text-blue-600">Lo que dicen nuestros clientes</h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              {testimonials.map((testimonial) => (
                <div key={testimonial.id} className="bg-white p-6 rounded-lg shadow-md">
                  <p className="mb-4 italic text-gray-600">"{testimonial.text}"</p>
                  <p className="font-semibold text-right text-blue-600">- {testimonial.name}</p>
                </div>
              ))}
            </div>
          </div>
        </section>

        <section id="preguntas-frecuentes" className="py-20 bg-white">
          <div className="container mx-auto px-4">
            <h2 className="text-4xl font-bold text-center mb-16 text-blue-600">Preguntas Frecuentes</h2>
            <div className="max-w-3xl mx-auto">
              {FAQ.map((item, index) => (
                <div key={index} className="mb-4">
                  <button
                    className="flex justify-between items-center w-full p-4 bg-blue-100 rounded-lg focus:outline-none"
                    onClick={() => toggleAccordion(index)}
                  >
                    <span className="font-semibold text-blue-700">{item.question}</span>
                    <ChevronDown
                      className={`transform transition-transform ${
                        activeAccordion === index ? 'rotate-180' : ''
                      } text-blue-500`}
                    />
                  </button>
                  {activeAccordion === index && (
                    <div className="p-4 bg-blue-50 rounded-b-lg">
                      <p className="text-gray-600">{item.answer}</p>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </section>

        <section id="cotizacion" className="py-4 bg-blue-500 text-white">
          <div className="container mx-auto text-center px-4">
            <div className="flex justify-center mb-4">
              <img 
                src={process.env.PUBLIC_URL + '/images/CHOCALAS.png'} 
                alt="Representación de servicio rápido y mejor precio" 
                className="w-1/2 md:w-1/4"
              />
            </div>
            <span className="inline-block bg-blue-600 text-white text-xs px-3 py-1 rounded-full uppercase tracking-wide mb-4">
              VENDETUCATALIZADOR.COM.MX
            </span>
            <h2 className="text-4xl font-bold mb-8">Obtén tu Cotización Ahora</h2>
            <p className="mb-8 text-xl">Completa nuestro formulario y recibe tu cotización en tu WhatsApp lo más pronto posible.</p>
            <a href="https://cotizador.vendetucatalizador.com.mx" className="bg-white text-blue-600 py-3 px-8 rounded-full text-lg font-semibold hover:bg-blue-50 transition duration-300 shadow-lg">
              Solicitar Cotización
            </a>
            <p className="mt-8 text-sm">⚠️ <strong>Importante:</strong> Por el momento solo contamos con servicio de recogida a domicilio en CDMX y algunos municipios del Estado de México. Sin embargo, podemos recibir tu catalizador por paquetería desde cualquier lugar de la República Mexicana. <strong>Tu pago está 100% garantizado.</strong></p>
            <p className="mt-8 text-sm"></p>
          </div>
        </section>
      </main>

      <footer className="bg-blue-600 text-white py-8">
        <div className="container mx-auto text-center px-4">
          <p>&copy; 2024 VendeTuCatalizador.com.mx - Todos los derechos reservados</p>
        </div>
      </footer>
    </div>
  );
}
